.h-40{
    height: 80vh;
  }
  
  
  .weblogo{
    height: 52px;
  }
  .pb-8{
      padding-bottom: 8rem;
  }
  
  .pt-8{
      padding-top: 8rem;
  }
  
  .pl-3{
      padding-left: 3rem;
  }
  
  .pr-3{
      padding-right: 3rem;
  }
  
  .ml-7{
      margin-left: 7rem;
  }
  
  .mr-7{
      margin-right: 7rem;
  }
  
  
  .h-90{
      height: 90vh;
  }
  
  
  .Text-white{
      color: white;
  }
  
  .W-fit{
      width: fit-content;
  }
  
  .w-web{
    width: -webkit-fill-available;
  }
  
  .ml-auto{
    margin-left: auto;
  }
  
  .menubar:hover{
    transform: scale(1.1);
    transition: 0.4s;
  }
  
  .back-gif{
    background-image: url('./Assets/Bannerwithoutlogo.png');
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 -10px 100px -10px #000000;
  }
  
  .mt-7{
    margin-top: 9rem;
  }
  
  .ml-2{
    padding-left: 2rem;
  }
  
  .mr-2{
    padding-right: 2rem;
  }
  
  .back-mql{
    background-image: url('./Assets/mql2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .beld:hover{
    background: linear-gradient( rgba(255, 255, 255, 0.1), rgb(0, 0, 0));
  }
  
  .back-blockchain{
    background-image: url('./Assets/blockchain.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .bd-2{
    border: 2px solid white;
  }
  
  .core{
    height: 80px;
  }
  
  .hovbd{
    border-bottom: 2px solid #333;
  }
  
  .imgsel{
    width: 180px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .imgselch{
    width: 150px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .imgselvu{
    width: 125px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .imgselnt{
    width: 130px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .imgseljs{
    width: 102px;
  }
  
  .imgselsd{
    width: 128px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .beleive{
    color: #727272;
  }
  .imgselnj{
    width: 106px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .imgselmn{
    width: 130px;
    margin-right: 20px;
    margin-left: 20px;
  }
  .imgselnxt{
    width: 160px;
    margin-right: 20px;
    margin-left: 20px;
  }
  .textser{
    background: linear-gradient(to right, #525252 0%, #ffffff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    transform: scale(0.9);
  }
  .textse{
    background: null;
  }
  
  .whatsapp{
    background: transparent;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 20px;
    /* margin-right: 40px; */
    border: 0px;
  }
  
  .whatsapp img{
    height: 70px;
  }
  
  .whatsapp:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
  
  
@media (max-width: 1025px){
    .ml-7{
      margin-left: 5rem;
    }
    .slidebar{
      width: 35%;
    }
    .mr-7{
      margin-right: 5rem;
    }
    p{
      font-size: 18px;
    }
    h1{
      font-size: 40px;
    }
    
    h2{
      font-size: 30px;
    }
  }
  
  @media (max-width: 800px){
    .core{
      height: 70px;
    }
    .sidebar-closed{
      width: 25px;
    }  
    .opc{
      height: 700px;
    }
    .slidebar{
      width: 40%;
    }
    .bd-style{
      border-left: 0px solid #ffffff;
      border-right: 0px solid #ffffff;
      border-top: 3px solid #ffffff;
      border-bottom: 3px solid #ffffff;
    }
  }
  
  @media (max-width: 500px){
    .ml-7{
      margin-left: 1rem;
    }
    .slidebar{
      width: 100%;
    }
    .mr-7{
      margin-right: 1rem;
    }
    p{
      font-size: 15px;
    }
    h1{
      font-size: 30px;
    }
    
    h2{
      font-size: 20px;
    }
    .core{
      height: 70px;
    }
    .opc{
      height: 700px;
    }
    .bd-style{
      border-left: 0px solid #ffffff;
      border-right: 0px solid #ffffff;
      border-top: 3px solid #ffffff;
      border-bottom: 3px solid #ffffff;
    }
  }