*{
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
}

.slidebar{
  background-image: linear-gradient(to right, rgb(197, 197, 197) , rgb(202, 202, 202));
  width: 30%;
}

.opcimg1{
  opacity: 100%;
  animation-name: sidebarshow1;
  animation-duration: 4s;
}
.opcimg2{
  opacity: 100%;
  animation-name: sidebarshow2;
  animation-duration: 4s;
}
.opcimg3{
  opacity: 100%;
  animation-name: sidebarshow3;
  animation-duration: 4s;
}
.opcimg4{
  opacity: 100%;
  animation-name: sidebarshow4;
  animation-duration: 4s;
}
.opcimg5{
  opacity: 100%;
  animation-name: sidebarshow5;
  animation-duration: 4s;
}
.opcimg6{
  opacity: 100%;
  animation-name: sidebarshow6;
  animation-duration: 4s;
}
.opcimg7{
  opacity: 100%;
  animation-name: sidebarshow7;
  animation-duration: 4s;
}
.opcimg8{
  opacity: 100%;
  animation-name: sidebarshow8;
  animation-duration: 4s;
}

.fix{
  position: fixed;
  top: 0px;
  right: 0px;
  opacity: 100%;
  animation-name: sidebarshow;
  animation-duration: 3s;
}

@keyframes sidebarshow {
  from{opacity: 0%;}
  to {opacity: 100%;}
  
}

@keyframes sidebarshow1 {
  from{opacity: 0%;}
  to {opacity: 100%;}
  
}

@keyframes sidebarshow2 {
  from{opacity: 0%;}
  to {opacity: 100%;}
  
}

@keyframes sidebarshow3 {
  from{opacity: 0%;}
  to {opacity: 100%;}
  
}

@keyframes sidebarshow4 {
  from{opacity: 0%;}
  to {opacity: 100%;}
  
}

@keyframes sidebarshow5 {
  from{opacity: 0%;}
  to {opacity: 100%;}
  
}

@keyframes sidebarshow6 {
  from{opacity: 0%;}
  to {opacity: 100%;}
  
}

@keyframes sidebarshow7 {
  from{opacity: 0%;}
  to {opacity: 100%;}
  
}

@keyframes sidebarshow8 {
  from{opacity: 0%;}
  to {opacity: 100%;}
  
}

p{
  font-size: 20px;
  font-weight: 400;
}

h1{
  font-size: 45px;
  font-weight: 500;
}

h2{
  font-size: 35px;
}
.Bg-blue{
  background-color: #000000;
}

.footer-back {
  color: #f2f2f2;
}

.bd-style{
  border-left: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
}

.icon-img{
  width: 40px;
  margin: 5px;
  border-radius: 50%;
}

.icon-img:hover{
  transform: scale(1);
  transition: 0.8s;
  background-image: linear-gradient(to right, rgb(0, 0, 0) , rgb(202, 202, 202));
  border-radius: 50%;
  cursor: pointer;
}

.p-8{
  padding: 8rem;
}
.input-form{
  color: white;
  box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	outline: none;
	display: block;
	width: 100%;
	padding: 7px;
	border: none;
	border-bottom: 1px solid rgb(255, 255, 255);
	background: transparent;
}

.sidebar-form{
  color: rgb(0, 0, 0);
  box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	outline: none;
	display: block;
	width: 100%;
	padding: 7px;
	border: none;
	border-bottom: 1px solid rgb(0, 0, 0);
	background: transparent;
}

.sidebar-closed{
  width: 30px;
}

.sidebar-closed:hover{
  transform: scale(1.1);
  transition: 0.4s;
}

.buttonall{
  color: white;
  background-color: #000000;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid white;
  border-radius: 20px;
  cursor: pointer;
}

.buttonall:hover{
  background-image: linear-gradient(to right, rgb(0, 0, 0) , rgb(202, 202, 202));
  border: 1px solid black;
}

.logoimg{
  height: 160px;
}

.fliplog:hover{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
